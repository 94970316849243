export const FooterBoxes = [
    {
        title: 'Связаться с нами',
        text: 'Напишите нам и наши менеджеры обязательно с Вами свяжуться',
        button: {
            text: 'Контакты',
            href: '/contacts',
            id: 'operator',
            type: 'simple'
        }
    },
    {
        title: 'Присоединяйтесь в нашу группу Telegram',
        text: 'Все последние новости и события нашего агентство!',
        button: {
            text: 'Перейти',
            href: 'https://t.me/simbios_agency',
            id: 'career',
            type: 'simple'
        }
    }
]


export const anchors = [
    {
        text: 'Наши работы',
        anchor: 'works'
    },
    // {
    //     text: 'Podcasts',
    //     anchor: 'podcasts'
    // },{
    //     text: 'Whitepapers',
    //     anchor: 'whitepapers'
    // },
]
